import { formatQuantity } from "@curaleaf-international/components";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { MouseEvent, useState } from "react";
import { Link as WLink } from "wouter";

import { Product } from "src/models";

interface IProps {
  product: Product;
}

const ProductRow = ({ product }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          component={WLink}
          onClick={handleClose}
          to={`/products/${product.id}/edit/`}
        >
          Edit
        </MenuItem>
      </Menu>
      <TableRow key={product.id}>
        <TableCell>{product.id}</TableCell>
        <TableCell>{formatQuantity(product.quantity, product.unit)}</TableCell>
        <TableCell>{product.sku}</TableCell>
        <TableCell>{product.gtin}</TableCell>
        <TableCell align="center">
          <IconButton
            aria-label="more"
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ProductRow;
