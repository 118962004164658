import {
  Dropzone,
  FormLinks,
  Title,
  ToastContext,
} from "@curaleaf-international/components";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingButton from "@mui/lab/LoadingButton";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Typography from "@mui/material/Typography";
import { useContext, useState } from "react";
import { useLocation } from "wouter";

import { useUploadFormulaCSVMutation } from "src/queries";

const UploadFormulasCSV = () => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const [files, setFiles] = useState<File[]>([]);
  const { mutateAsync: upload, isPending } = useUploadFormulaCSVMutation();

  const onClick = async () => {
    try {
      const dataTransfer = new DataTransfer();
      files.forEach((file) => dataTransfer.items.add(file));
      await upload({ files: dataTransfer.files });
      addToast("Formulas uploaded", "success");
      setLocation(`/formulas/`);
    } catch {
      addToast("Try again", "error");
    }
  };
  // eslint-disable-next-line @typescript-eslint/require-await
  const onDrop = async (newFiles: FileList) => {
    setFiles(
      [...files, ...newFiles].filter((file) => file.type === "text/csv"),
    );
  };
  return (
    <>
      <Title
        breadcrumbs={[{ to: "/formulas/", label: "Formulas" }]}
        title="Upload Formulas"
      />
      <Card>
        <CardContent>
          <Dropzone label="Add file(s)" onDrop={onDrop} />
          <List>
            {files.map((file) => (
              <ListItem
                key={file.name}
                secondaryAction={
                  <IconButton
                    aria-label="delete"
                    edge="end"
                    onClick={() =>
                      setFiles(files.filter((checkFile) => checkFile !== file))
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemText primary={file.name} />
              </ListItem>
            ))}
          </List>

          <Typography variant="body1">
            The CSV should contain a line for every formula, with each line
            having the following data (columns) in the given order.
          </Typography>

          <List dense disablePadding sx={{ marginBottom: 4 }}>
            <ListSubheader>Formula data</ListSubheader>
            <ListItem>
              <ListItemText
                primary="Global Item SKU"
                secondary="Can be empty"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Unit Barcode"
                secondary="Cannot be empty"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Global Product Name"
                secondary="Can be empty"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Registered name"
                secondary="Can be empty"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Formulary name"
                secondary="Cannot be empty"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Country availability"
                secondary="Cannot be empty"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Legacy Item Number"
                secondary="Cannot be empty"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="Brand" secondary="Cannot be empty" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Cultivator" secondary="Can be empty" />
            </ListItem>
            <ListItem>
              <ListItemText primary="GMP/GACP" secondary="Can be empty" />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Product category"
                secondary="Can be empty"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="Sub Category" secondary="Can be empty" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Flower Grade" secondary="Can be empty" />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Product Strength"
                secondary="Can be empty"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="Strain Type" secondary="Can be empty" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Parent Strain" secondary="Can be empty" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Strain Name" secondary="Can be empty" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Terpene" secondary="Cannot be empty" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Ingredients" secondary="Cannot be empty" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Intended Use" secondary="Can be empty" />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Product Form"
                secondary="Cannot be empty"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="UOM" secondary="Cannot be empty" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Fill weigh" secondary="Cannot be empty" />
            </ListItem>
          </List>
        </CardContent>
        <Divider />
        <CardActions>
          <LoadingButton
            disabled={files.length === 0}
            loading={isPending}
            onClick={onClick}
            variant="contained"
          >
            Upload
          </LoadingButton>
          <FormLinks links={[{ label: "Back", to: `/formulas/` }]} />
        </CardActions>
      </Card>
    </>
  );
};
export default UploadFormulasCSV;
