import {
  FormLinks,
  SelectField,
  SubmitButton,
  Title,
  ToastContext,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import { z } from "zod";

import { LeafletAudience } from "src/models";
import { useEditLeafletAudienceMutation, useLeafletQuery } from "src/queries";

const FormSchema = z.object({
  audience: z.string(),
});
type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  leafletId: number;
}

const EditLeafletAudience = ({ leafletId }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { data: leaflet } = useLeafletQuery(leafletId);
  const { mutateAsync: edit } = useEditLeafletAudienceMutation(leafletId);
  const defaultValues = {
    audience: leaflet?.audience ?? LeafletAudience.PATIENT,
  };
  const methods = useForm<FormType, any, ValidatedType>({
    defaultValues,
    resolver: zodResolver(FormSchema),
  });

  useEffect(() => methods.reset(defaultValues), [leaflet]);

  const onSubmit = async (data: ValidatedType) => {
    try {
      await edit({
        audience: data.audience as LeafletAudience,
      });
      addToast("Leaflet updated", "success");
      setLocation(`/formulas/${leaflet?.formulaId}/`);
    } catch {
      addToast("Try again", "error");
    }
  };

  return (
    <>
      <Title title="Update Leaflet Audience" />
      <Card>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CardContent>
              <SelectField
                autoComplete="off"
                fullWidth
                label="Leaflet Audience"
                name="audience"
                options={Object.values(LeafletAudience).map((value) => ({
                  value,
                }))}
                required
              />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton label="Change" />
              <FormLinks
                links={[
                  { label: "Back", to: `/formulas/${leaflet?.formulaId}/` },
                ]}
              />
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default EditLeafletAudience;
