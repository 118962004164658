import { formatDateTime } from "@curaleaf-international/components";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { MouseEvent, useState } from "react";
import { Link as WLink } from "wouter";

import { FormulaHistory } from "src/models";

interface IProps {
  history: FormulaHistory;
}

const HistoryRow = ({ history }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          component={WLink}
          onClick={handleClose}
          to={`/formula-histories/${history.id}/approval/`}
        >
          {history.approved === null ? "Approve" : "Disapprove"}
        </MenuItem>
      </Menu>
      <TableRow>
        <TableCell>{history.nameInternal}</TableCell>
        <TableCell>{history.qualityReference}</TableCell>
        <TableCell>
          {history.staffEmail}
          <br />
          On {formatDateTime(history.timestamp)}
        </TableCell>
        <TableCell>
          {history.approverEmail}
          {history.approved !== null ? (
            <>
              <br />
              On {formatDateTime(history.approved)}
            </>
          ) : null}
        </TableCell>
        <TableCell align="center">
          <IconButton
            aria-label="more"
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default HistoryRow;
