import {
  FormLinks,
  ToastContext,
  Title,
} from "@curaleaf-international/components";
import LoadingButton from "@mui/lab/LoadingButton";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useEffect, useContext } from "react";
import { useLocation } from "wouter";

import { useApproveLeafletMutation, useLeafletQuery } from "src/queries";

interface IProps {
  leafletId: number;
}

const ApproveLeaflet = ({ leafletId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: leaflet, refetch } = useLeafletQuery(leafletId);

  const { mutateAsync: approve, isPending } =
    useApproveLeafletMutation(leafletId);

  const label = leaflet?.approved === null ? "Approve" : "Disapprove";
  useEffect(() => {}, [label]);

  const handleClick = async () => {
    try {
      await approve(leaflet?.approved === null);
      setLocation(`/formulas/${leaflet?.formulaId}/`);
      addToast(`Leaflet ${label}d`, "success");
      await refetch();
    } catch {
      addToast("Try Again", "error");
    }
  };

  return (
    <>
      <Title title="Approve Leaflet" />
      <Card>
        <CardContent>
          Clicking this button will <strong>{label}</strong> the Leaflet.
        </CardContent>
        <Divider />
        <CardActions>
          <LoadingButton
            color="primary"
            loading={isPending}
            onClick={handleClick}
            variant="contained"
          >
            {label}
          </LoadingButton>
          <FormLinks
            links={[{ label: "Back", to: `/formulas/${leaflet?.formulaId}/` }]}
          />
        </CardActions>
      </Card>
    </>
  );
};

export default ApproveLeaflet;
