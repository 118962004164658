import {
  FormLinks,
  SelectField,
  SubmitButton,
  TextField,
  zodDecimal,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import CannaboidField from "src/components/FormulaForm/CannaboidField";
import MarketField from "src/components/MarketField";
import {
  FlowerGrade,
  Form,
  GmpGacp,
  MicrobeStandard,
  Species,
} from "src/models";
import { useBrandsQuery } from "src/queries";

const INGREDIENTS = [
  "*delta-9-tetrahydrocannabinol",
  "Cannabidiol Extract",
  "Cannabidiol Isolate",
  "Cannabidiol",
  "Cannabis sativa L*",
  "Cannabis sativa L. extract",
  "Cochineal red A (E124)",
  "containing Delta-9-tetrahydrocannabinol",
  "Delta-9-tetrahydrocannabinol",
  "Glucose",
  "Jack Herer Terpenes",
  "MCT Oil",
  "Propylene glycol (E1520)",
  "Sorbitol",
  "Sucrose",
  "Terpenes",
  "Tartrazine (E102)",
  "Triacetin (E1518)",
  "Triglycerides medium-chain",
  "ssp. Indica",
  "ssp. Sativa",
];
const TERPENES = [
  "Caryophyllene",
  "D-Limonene",
  "R-limonene",
  "β-Myrcene",
  "α-pinene",
  "β-Pinene",
  "Linalool",
  "Humulene",
  "Delta-3-Carene",
  "Terpinolene",
  "trans-Ocimene",
  "β-Caryophyllene",
  "Guaiol",
  "α-Bisabolol",
  "α-Cendrene",
  "α-Humulene",
  "Limonene",
  "γ-Elemene",
  "Fenchol",
  "Ocimene",
  "Sabinene",
  "Farnesene",
  "trans-Neolidol",
  "trans-Caryophyllene",
  "Valencene",
  "E-Neridol",
  "Z-Neridiol",
  "Eucalyptol",
];

export const NO_SPECIES = "NO_SPECIES";

const FormSchema = z
  .object({
    appearance: z.string(),
    aroma: z.string(),
    brandId: z.coerce.number(),
    cbdMin: zodDecimal().nullable(),
    cbdMax: zodDecimal().nullable(),
    cbgMin: zodDecimal().nullable(),
    cbgMax: zodDecimal().nullable(),
    cbnMin: zodDecimal().nullable(),
    cbnMax: zodDecimal().nullable(),
    controlled: z.string().transform((val) => val === "true"),
    flowerGrade: z.string(),
    form: z.string(),
    gmpGacp: z.string(),
    grower: z.string(),
    ingredients: z.array(z.string()),
    intendedUse: z.string(),
    irradiationType: z.string(),
    manufacturer: z.string(),
    market: z.string().min(1),
    microbeStandard: z.string(),
    nameInternal: z.string().min(1),
    parentStrain: z.string(),
    registeredName: z.string(),
    species: z.string().nullable(),
    strain: z.string(),
    terpenes: z.array(z.string()),
    thcMin: zodDecimal().nullable(),
    thcMax: zodDecimal().nullable(),
  })
  .transform((values) => {
    if (values.form === "FLOWER") {
      values.cbdMin = values.cbdMin?.times(10) ?? null;
      values.cbdMax = values.cbdMax?.times(10) ?? null;
      values.cbgMin = values.cbgMin?.times(10) ?? null;
      values.cbgMax = values.cbgMax?.times(10) ?? null;
      values.cbnMin = values.cbnMin?.times(10) ?? null;
      values.cbnMax = values.cbnMax?.times(10) ?? null;
      values.thcMin = values.thcMin?.times(10) ?? null;
      values.thcMax = values.thcMax?.times(10) ?? null;
    }
    return values;
  })
  .superRefine((values, ctx) => {
    if ((values.cbdMin === null) !== (values.cbdMax === null)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Min and Max must both be defined or both absent",
        path: ["cbdMin"],
      });
    } else if (values.cbdMin?.greaterThan(values.cbdMax ?? 0)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Min must be less than Max",
        path: ["cbdMin"],
      });
    }
    if ((values.cbgMin === null) !== (values.cbgMax === null)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Min and Max must both be defined or both absent",
        path: ["cbgMin"],
      });
    } else if (values.cbgMin?.greaterThan(values.cbgMax ?? 0)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Min must be less than Max",
        path: ["cbgMin"],
      });
    }
    if ((values.cbnMin === null) !== (values.cbnMax === null)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Min and Max must both be defined or both absent",
        path: ["cbnMin"],
      });
    } else if (values.cbnMin?.greaterThan(values.cbnMax ?? 0)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Min must be less than Max",
        path: ["cbnMin"],
      });
    }
    if ((values.thcMin === null) !== (values.thcMax === null)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Min and Max must both be defined or both absent",
        path: ["thcMin"],
      });
    } else if (values.thcMin?.greaterThan(values.thcMax ?? 0)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Min must be less than Max",
        path: ["thcMin"],
      });
    }
  });
export type FormType = z.input<typeof FormSchema>;
export type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  defaultValues: FormType;
  disabled?: boolean;
  label: string;
  onSubmit: (data: ValidatedType) => Promise<void>;
}

const FormulaForm = ({ defaultValues, disabled, label, onSubmit }: IProps) => {
  const { data: brands } = useBrandsQuery();

  const methods = useForm<FormType, any, ValidatedType>({
    defaultValues,
    resolver: zodResolver(FormSchema),
  });
  const formType = methods.watch("form");

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <CardContent>
          <TextField
            autoComplete="off"
            fullWidth
            label="Internal name"
            name="nameInternal"
            required
          />
          <MarketField fullWidth label="Market" name="market" required />
          <TextField
            autoComplete="off"
            fullWidth
            label="Registered market name"
            name="registeredName"
          />
          <SelectField
            autoComplete="off"
            fullWidth
            label="Brand"
            name="brandId"
            options={
              brands?.map((brand) => ({
                label: brand.name,
                value: brand.id.toString(),
              })) ?? []
            }
            required
          />
          <SelectField
            autoComplete="off"
            fullWidth
            label="Controlled"
            name="controlled"
            options={[
              { value: "true", label: "Yes" },
              { value: "false", label: "No" },
            ]}
            required
          />
          <SelectField
            autoComplete="off"
            fullWidth
            label="Form"
            name="form"
            options={Object.values(Form).map((value) => ({ value }))}
            required
          />
          <SelectField
            autoComplete="off"
            fullWidth
            label="Species"
            name="species"
            options={[
              { value: NO_SPECIES, label: "NO SPECIES" },
              ...Object.values(Species).map((value) => ({ value })),
            ]}
          />
          <TextField
            autoComplete="off"
            fullWidth
            label="Strain"
            name="strain"
          />
          <SelectField
            autoComplete="off"
            fullWidth
            label="Ingredients"
            multiple
            name="ingredients"
            options={INGREDIENTS.map((value) => ({ value }))}
            required
          />
          <SelectField
            autoComplete="off"
            fullWidth
            label="Terpenes"
            multiple
            name="terpenes"
            options={TERPENES.map((value) => ({ value }))}
          />
          <CannaboidField form={formType} name="thc" />
          <CannaboidField form={formType} name="cbd" />
          <CannaboidField form={formType} name="cbg" />
          <CannaboidField form={formType} name="cbn" />
          <TextField
            autoComplete="off"
            fullWidth
            label="Appearance"
            name="appearance"
          />
          <TextField autoComplete="off" fullWidth label="Aroma" name="aroma" />
          <TextField
            autoComplete="off"
            fullWidth
            label="Grower"
            name="grower"
          />
          <TextField
            autoComplete="off"
            fullWidth
            label="Parent Strain"
            name="parentStrain"
          />
          {formType === "FLOWER" && (
            <>
              <SelectField
                autoComplete="off"
                fullWidth
                label="Microbial Standard"
                name="microbeStandard"
                options={Object.values(MicrobeStandard).map((value) => ({
                  value,
                }))}
                required
              />
              <SelectField
                autoComplete="off"
                fullWidth
                label="Flower Grade"
                name="flowerGrade"
                options={Object.values(FlowerGrade).map((value) => ({ value }))}
                required
              />
            </>
          )}
          <TextField
            autoComplete="off"
            fullWidth
            label="Intended Use"
            name="intendedUse"
          />
          <SelectField
            autoComplete="off"
            fullWidth
            label="GMP/GACP"
            name="gmpGacp"
            options={Object.values(GmpGacp).map((value) => ({ value }))}
          />
          <TextField
            autoComplete="off"
            fullWidth
            label="Manufacturer"
            name="manufacturer"
          />
          <TextField
            autoComplete="off"
            fullWidth
            label="Irradiation Type"
            name="irradiationType"
          />
        </CardContent>
        <Divider />
        <CardActions>
          <SubmitButton disabled={disabled} label={label} />
          <FormLinks links={[{ label: "Back", to: "/formulas/" }]} />
        </CardActions>
      </form>
    </FormProvider>
  );
};

export default FormulaForm;
