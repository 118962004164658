import {
  formatDateTime,
  SkeletonRow,
} from "@curaleaf-international/components";
import DownloadIcon from "@mui/icons-material/Download";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid2";
import Link from "@mui/material/Link";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { Formula, PILType } from "src/models";
import { useFormulaPILsQuery } from "src/queries";

interface IProps {
  formula?: Formula;
}

const PILs = ({ formula }: IProps) => {
  const { data: pils } = useFormulaPILsQuery(formula?.id);

  let content;
  if (formula === undefined) {
    content = (
      <Card>
        <CardContent>
          <Skeleton width="100%" />
        </CardContent>
      </Card>
    );
  } else if (formula.pilType === PILType.ABSENT) {
    content = (
      <Card>
        <CardContent>
          This formula does not have a PIL in this system
        </CardContent>
      </Card>
    );
  } else {
    const pilCard = (
      <Card>
        <CardContent>
          <img
            alt="QR Code to PIL"
            width={300}
            src={`/v1/formulas/${formula.id}/qr-code/${formula.market}/?format=png`}
          />
        </CardContent>
        <CardActions>
          <Button
            component="a"
            disabled={formula === undefined}
            download
            href={`/v1/formulas/${formula?.id}/qr-code/${formula?.market}/?format=svg`}
            startIcon={<DownloadIcon />}
          >
            SVG
          </Button>
          <Button
            component="a"
            disabled={formula === undefined}
            download
            href={`/v1/formulas/${formula?.id}/qr-code/${formula?.market}/?format=png`}
            startIcon={<DownloadIcon />}
          >
            PNG
          </Button>
          <Button
            component="a"
            href={`/v1/formula-histories/${formula?.historyId}/pil/${formula?.market}/`}
            target="_blank"
          >
            View
          </Button>
        </CardActions>
      </Card>
    );
    if (formula?.pilType === PILType.UPLOADED) {
      content = (
        <Grid container spacing={2}>
          <Grid size={{ xs: 6, sm: 6, md: 4 }}>{pilCard}</Grid>
          <Grid size="grow">
            <Card>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Filename</TableCell>
                      <TableCell>Uploaded</TableCell>
                      <TableCell>Uploader</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pils?.map((pil) => (
                      <TableRow key={pil.id}>
                        <TableCell>
                          <Link href={`/v1/pils/${pil.id}/`}>{pil.name}</Link>
                        </TableCell>
                        <TableCell>{formatDateTime(pil.timestamp)}</TableCell>
                        <TableCell>{pil.uploaderEmail}</TableCell>
                      </TableRow>
                    )) ?? <SkeletonRow cols={3} />}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
        </Grid>
      );
    } else {
      content = <>{pilCard}</>;
    }
  }

  return <>{content}</>;
};

export default PILs;
