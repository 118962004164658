import {
  FormLinks,
  SubmitButton,
  TextField,
  Title,
  ToastContext,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import { z } from "zod";

import { FormulaState } from "src/models";
import { useEditFormulaStateMutation } from "src/queries";

const FormSchema = z.object({
  reason: z.string().trim().min(1),
});
type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  formulaId: string;
  state: FormulaState;
}

const EditFormulaState = ({ formulaId, state }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { mutateAsync: edit } = useEditFormulaStateMutation(formulaId);
  const methods = useForm<FormType>({
    defaultValues: { reason: "" },
    resolver: zodResolver(FormSchema),
  });

  const onSubmit = async (data: ValidatedType) => {
    try {
      await edit({
        reason: data.reason,
        state,
      });
      addToast("Formula updated", "success");
      setLocation(`/formulas/${formulaId}/`);
    } catch {
      addToast("Try again", "error");
    }
  };

  let label = "Created";
  if (state === FormulaState.ARCHIVED) {
    label = "Archive";
  } else if (state === FormulaState.ACTIVE) {
    label = "Unarchive";
  }

  return (
    <>
      <Title title="Update formula state" />
      <Card>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CardContent>
              <TextField
                fullWidth
                helperText={`Please explain why this formula should be in the state ${label}`}
                label="Reason for change"
                name="reason"
                autoComplete="off"
                required
              />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton label={label} />
              <FormLinks
                links={[{ label: "Back", to: `/formulas/${formulaId}/` }]}
              />
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default EditFormulaState;
