import { zodDecimal, Unit } from "@curaleaf-international/components";
import { z } from "zod";

export const productSchema = z.object({
  id: z.string(),
  formulaId: z.string(),
  gtin: z.string().nullable(),
  quantity: zodDecimal(),
  sku: z.string().nullable(),
  unit: z.nativeEnum(Unit),
});

export type Product = z.infer<typeof productSchema>;
export const newProduct = (data: unknown): Product => productSchema.parse(data);
