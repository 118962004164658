import { Title } from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { Link } from "wouter";

import RequiresRoles from "src/components/RequiresRoles";
import { StaffRole } from "src/models";
import BrandDetails from "src/pages/Brand/BrandDetails";
import { useBrandQuery } from "src/queries";

interface IProps {
  brandId: number;
}

const Brand = ({ brandId }: IProps) => {
  const { data: brand } = useBrandQuery(brandId);

  return (
    <>
      <Title
        actions={
          <RequiresRoles roles={[StaffRole.DEVELOPER, StaffRole.DATA_MASTER]}>
            <Button
              component={Link}
              to={`/brands/${brandId}/edit/`}
              endIcon={<KeyboardArrowDownIcon />}
              variant="contained"
            >
              Edit
            </Button>
          </RequiresRoles>
        }
        breadcrumbs={[
          {
            to: "/brands/",
            label: "Brands",
          },
        ]}
        title={brand?.name ?? ""}
      />
      <Card>
        <BrandDetails brand={brand} />
      </Card>
    </>
  );
};
export default Brand;
