import {
  APIError,
  Title,
  ToastContext,
} from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import axios from "axios";
import { useContext, useMemo } from "react";
import { useLocation } from "wouter";

import FormulaForm, {
  ValidatedType,
  NO_SPECIES,
} from "src/components/FormulaForm";
import {
  FlowerGrade,
  Form,
  GmpGacp,
  MicrobeStandard,
  Species,
} from "src/models";
import { useCreateFormulaMutation } from "src/queries";

const CreateFormula = () => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { mutateAsync: create } = useCreateFormulaMutation();
  const defaultValues = useMemo(
    () => ({
      appearance: "",
      aroma: "",
      brandId: "" as unknown as number,
      cbdMin: null,
      cbdMax: null,
      cbgMin: null,
      cbgMax: null,
      cbnMin: null,
      cbnMax: null,
      controlled: "true",
      flowerGrade: FlowerGrade.STANDARD,
      form: Form.FLOWER,
      gmpGacp: GmpGacp.GMP,
      grower: "",
      ingredients: [],
      intendedUse: "",
      irradiationType: "",
      manufacturer: "",
      market: "gbr",
      microbeStandard: "",
      nameInternal: "",
      parentStrain: "",
      registeredName: "",
      species: NO_SPECIES,
      strain: "",
      terpenes: [],
      thcMin: null,
      thcMax: null,
    }),
    [],
  );

  const onSubmit = async (data: ValidatedType) => {
    try {
      await create({
        appearance:
          data.appearance.trim() !== "" ? data.appearance.trim() : null,
        aroma: data.aroma.trim() !== "" ? data.aroma.trim() : null,
        brandId: data.brandId,
        cbd:
          data.cbdMin === null || data.cbdMax === null
            ? null
            : [data.cbdMin, data.cbdMax],
        cbg:
          data.cbgMin === null || data.cbgMax === null
            ? null
            : [data.cbgMin, data.cbgMax],
        cbn:
          data.cbnMin === null || data.cbnMax === null
            ? null
            : [data.cbnMin, data.cbnMax],
        controlled: data.controlled,
        flowerGrade:
          data.form === "FLOWER" ? (data.flowerGrade as FlowerGrade) : null,
        form: data.form as Form,
        gmpGacp: data.gmpGacp as GmpGacp,
        grower: data.grower.trim() !== "" ? data.grower.trim() : null,
        ingredients: data.ingredients,
        intendedUse:
          data.intendedUse.trim() !== "" ? data.intendedUse.trim() : null,
        irradiationType:
          data.irradiationType.trim() !== ""
            ? data.irradiationType.trim()
            : null,
        manufacturer:
          data.manufacturer.trim() !== "" ? data.manufacturer.trim() : null,
        market: data.market,
        microbeStandard:
          data.form === "FLOWER"
            ? (data.microbeStandard as MicrobeStandard)
            : null,
        nameInternal: data.nameInternal,
        parentStrain:
          data.parentStrain.trim() !== "" ? data.parentStrain.trim() : null,
        registeredName:
          data.registeredName.trim() !== "" ? data.registeredName.trim() : null,
        species: data.species === NO_SPECIES ? null : (data.species as Species),
        strain: data.strain.trim() !== "" ? data.strain.trim() : null,
        terpenes: data.terpenes,
        thc:
          data.thcMin === null || data.thcMax === null
            ? null
            : [data.thcMin, data.thcMax],
      });
      addToast("Formula added", "success");
      setLocation("/formulas/");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const apiError = error.response?.data as APIError;
        if (apiError.code === "NAME_EXISTS") {
          addToast("This name has already been used.", "error");
        } else {
          addToast("An unexpected error occurred. Please try again.", "error");
        }
      } else {
        addToast("An unexpected error occurred. Please try again.", "error");
      }
    }
  };

  return (
    <>
      <Title
        breadcrumbs={[
          {
            to: "/formulas/",
            label: "Formulas",
          },
          { label: "Add formula" },
        ]}
        title="Add formula"
      />
      <Card>
        <FormulaForm
          defaultValues={defaultValues}
          label="Add"
          onSubmit={onSubmit}
        />
      </Card>
    </>
  );
};

export default CreateFormula;
