import { Title, ToastContext, Unit } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import { useContext, useMemo } from "react";
import { useLocation } from "wouter";

import ProductForm from "src/components/ProductForm";
import { useCreateProductMutation, useFormulaQuery } from "src/queries";

interface IProps {
  formulaId: string;
}

const CreateProduct = ({ formulaId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: formula } = useFormulaQuery(formulaId);
  const { mutateAsync: create } = useCreateProductMutation();
  const defaultValues = useMemo(
    () => ({
      gtin: "",
      quantity: "",
      sku: "",
      unit: Unit.G,
    }),
    [],
  );

  const onSubmit = async (data: any) => {
    try {
      await create({
        formulaId,
        gtin: data.gtin.trim() === "" ? null : data.gtin.trim(),
        quantity: data.quantity,
        sku: data.sku.trim() === "" ? null : data.sku.trim(),
        unit: data.unit,
      });
      addToast("Product added", "success");
      setLocation(`/formulas/${formulaId}/`);
    } catch {
      addToast("Error adding product", "error");
    }
  };

  return (
    <>
      <Title
        breadcrumbs={[
          {
            to: "/formulas/",
            label: "Formulas",
          },
          {
            to: `/formulas/${formulaId}/`,
            label: formula?.nameInternal ?? "",
          },
        ]}
        title="Add product"
      />
      <Card>
        <ProductForm
          defaultValues={defaultValues}
          label="Add"
          onSubmit={onSubmit}
        />
      </Card>
    </>
  );
};

export default CreateProduct;
