import {
  zodDecimal,
  DecimalField,
  FormLinks,
  SubmitButton,
  TextField,
  Unit,
  UnitField,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

const FormSchema = z.object({
  gtin: z.string(),
  quantity: zodDecimal(),
  sku: z.string(),
  unit: z.nativeEnum(Unit),
});
export type FormType = z.input<typeof FormSchema>;
export type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  defaultValues: FormType;
  disabled?: boolean;
  label: string;
  onSubmit: (data: ValidatedType) => Promise<void>;
}

const ProductForm = ({ defaultValues, disabled, label, onSubmit }: IProps) => {
  const methods = useForm<FormType, any, ValidatedType>({
    defaultValues,
    resolver: zodResolver(FormSchema),
  });

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <CardContent>
          <DecimalField
            autoComplete="off"
            fullWidth
            label="Quantity"
            name="quantity"
            required
          />
          <UnitField fullWidth label="Unit" name="unit" required />
          <TextField
            autoComplete="off"
            fullWidth
            helperText="Stock Keeping Unit"
            label="SKU"
            name="sku"
          />
          <TextField
            autoComplete="off"
            fullWidth
            helperText="Global Trade Item Number"
            label="GTIN"
            name="gtin"
          />
        </CardContent>
        <Divider />
        <CardActions>
          <SubmitButton disabled={disabled} label={label} />
          <FormLinks links={[{ label: "Back", to: "/products/" }]} />
        </CardActions>
      </form>
    </FormProvider>
  );
};

export default ProductForm;
