import { SkeletonRow } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid2";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { Formula, LeafletAudience, LeafletType } from "src/models";
import LeafletRow from "src/pages/Formula/LeafletRow";
import QRCard from "src/pages/Formula/QRCard";
import { useFormulaLeafletsQuery } from "src/queries";

interface IProps {
  formula?: Formula;
}

const Leaflets = ({ formula }: IProps) => {
  const { data: leaflets } = useFormulaLeafletsQuery(formula?.id);

  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12, sm: 6 }}>
        <QRCard audience={LeafletAudience.PATIENT} formulaId={formula?.id} />
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <QRCard
          audience={LeafletAudience.HEALTH_CARE_PROFESSIONAL}
          formulaId={formula?.id}
        />
      </Grid>
      {formula?.leafletType === LeafletType.UPLOADED ? (
        <Grid size={12}>
          <Card>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Filename</TableCell>
                    <TableCell>Audience</TableCell>
                    <TableCell>Uploaded by</TableCell>
                    <TableCell>Approved by</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leaflets?.map((leaflet) => (
                    <LeafletRow key={leaflet.id} leaflet={leaflet} />
                  )) ?? <SkeletonRow cols={5} />}
                  {leaflets?.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5}>No Files</TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default Leaflets;
