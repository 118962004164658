import {
  FormLinks,
  SelectField,
  SubmitButton,
  Title,
  ToastContext,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import { z } from "zod";

import { LeafletType } from "src/models";
import {
  useEditFormulaLeafletTypeMutation,
  useFormulaQuery,
} from "src/queries";

const FormSchema = z.object({
  leafletType: z.string(),
});
type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  formulaId: string;
}

const EditFormulaLeafletType = ({ formulaId }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { data: formula } = useFormulaQuery(formulaId);
  const { mutateAsync: edit } = useEditFormulaLeafletTypeMutation(formulaId);
  const defaultValues = {
    leafletType: formula?.leafletType ?? LeafletType.GENERATED,
  };
  const methods = useForm<FormType, any, ValidatedType>({
    defaultValues,
    resolver: zodResolver(FormSchema),
  });

  useEffect(() => methods.reset(defaultValues), [formula]);

  const onSubmit = async (data: ValidatedType) => {
    try {
      await edit({
        leafletType: data.leafletType as LeafletType,
      });
      addToast("Formula updated", "success");
      setLocation(`/formulas/${formulaId}/`);
    } catch {
      addToast("Try again", "error");
    }
  };

  return (
    <>
      <Title title="Update Leaflet Type" />
      <Card>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CardContent>
              <SelectField
                autoComplete="off"
                fullWidth
                label="Leaflet Type"
                name="leafletType"
                options={Object.values(LeafletType).map((value) => ({ value }))}
                required
              />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton label="Change" />
              <FormLinks
                links={[{ label: "Back", to: `/formulas/${formulaId}/` }]}
              />
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default EditFormulaLeafletType;
