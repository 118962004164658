import {
  sortByKey,
  useHash,
  SkeletonRow,
  Title,
} from "@curaleaf-international/components";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link as WLink } from "wouter";

import PaginatedSearch from "src/components/PaginatedSearch";
import TablePagination from "src/components/TablePagination";
import TableSortLabel from "src/components/TableSortLabel";
import { Formula, FormulaState } from "src/models";
import { useFormulasQuery } from "src/queries";
import { usePaginationSettings } from "src/queries";

type Tabs = "unapproved" | "approved";

const Formulas = () => {
  const [tab, setTab] = useHash<Tabs>("unapproved");
  const [
    paginationSettings,
    { setPageNumber, setPageSize, toggleSort, setSearchQuery },
  ] = usePaginationSettings<Formula>({
    pageNumber: 0,
    pageSize: 100,
    sortColumn: "nameInternal",
    sortDirection: "desc",
  });

  const { data: formulas } = useFormulasQuery({
    approved: tab === "approved",
    paginationSettings,
    market: "gbr",
    state: FormulaState.ACTIVE,
  });
  const { totalRecordCount } = formulas || {};

  let rows: React.ReactNode = <SkeletonRow cols={3} />;

  if (formulas !== undefined && formulas.rows.length > 0) {
    rows = formulas?.rows
      .sort(
        sortByKey(
          (formula: any) =>
            formula[paginationSettings.sortColumn ?? "nameInternal"],
          paginationSettings.sortDirection === "desc" ? "desc" : "asc",
        ),
      )
      .map((formula: any) => (
        <TableRow key={formula.id}>
          <TableCell>
            <Link component={WLink} to={`/formulas/${formula.id}/`}>
              {formula.nameInternal}
            </Link>
          </TableCell>
          <TableCell>{formula.market.toUpperCase()}</TableCell>
          <TableCell>{formula.qualityReference}</TableCell>
        </TableRow>
      ));
  } else if (formulas !== undefined) {
    rows = (
      <TableRow>
        <TableCell colSpan={3}>No formulas found</TableCell>
      </TableRow>
    );
  }

  return (
    <>
      <Title title="Quality" />
      <Card>
        <TabContext value={tab}>
          <TabList
            onChange={(_, value: string) => setTab(value as Tabs)}
            sx={{ borderBottom: 1, borderColor: "divider", paddingLeft: 2 }}
          >
            <Tab label="Unapproved" value="unapproved" />
            <Tab label="Approved" value="approved" />
          </TabList>
        </TabContext>
        <CardContent>
          <PaginatedSearch
            setSearchQuery={setSearchQuery}
            paginationSettings={paginationSettings}
          />
        </CardContent>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel<Formula>
                    columnName="nameInternal"
                    paginationSettings={paginationSettings}
                    toggleSort={toggleSort}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel<Formula>
                    columnName="market"
                    paginationSettings={paginationSettings}
                    toggleSort={toggleSort}
                  >
                    Brand
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel<Formula>
                    columnName={"brandId"}
                    paginationSettings={paginationSettings}
                    toggleSort={toggleSort}
                  >
                    Quality Reference
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
          <TablePagination<Formula>
            recordCount={totalRecordCount}
            paginationSettings={paginationSettings}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            rowsPerPageOptions={[100, 500, 750, 1000]}
          />
        </TableContainer>
      </Card>
    </>
  );
};

export default Formulas;
