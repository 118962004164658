import { Title, ToastContext } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import { useContext, useMemo } from "react";
import { useLocation } from "wouter";

import BrandForm, { ValidatedType } from "src/components/BrandForm";
import { useEditBrandMutation, useBrandQuery } from "src/queries";

interface IProps {
  brandId: number;
}

const EditBrand = ({ brandId }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { data: brand } = useBrandQuery(brandId);
  const { mutateAsync: edit } = useEditBrandMutation(brandId);

  const defaultValues = useMemo(
    () => ({
      company: brand?.company ?? "",
      name: brand?.name ?? "",
    }),
    [brand],
  );

  const onSubmit = async (data: ValidatedType) => {
    try {
      await edit(data);
      addToast("Brand edited", "success");
      setLocation("/brands/");
    } catch {
      addToast("Error editing brand", "error");
    }
  };

  return (
    <>
      <Title
        breadcrumbs={[
          {
            to: "/brands/",
            label: "Brands",
          },
          {
            to: `/brands/${brandId}/`,
            label: brand?.name ?? "",
          },
        ]}
        title="Edit brand"
      />
      <Card>
        <BrandForm
          defaultValues={defaultValues}
          disabled={brand === undefined}
          label="Edit"
          onSubmit={onSubmit}
        />
      </Card>
    </>
  );
};

export default EditBrand;
