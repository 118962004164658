import { z } from "zod";

const pilSchema = z.object({
  id: z.number().int().positive(),
  name: z.string(),
  timestamp: z.coerce.date(),
  uploaderEmail: z.string(),
  uploaderId: z.number().int().positive(),
});

export type PIL = z.infer<typeof pilSchema>;

export const newPIL = (data: unknown): PIL => pilSchema.parse(data);
