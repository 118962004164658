import { z } from "zod";

export const brandSchema = z.object({
  id: z.number(),
  company: z.string(),
  name: z.string(),
});

export type Brand = z.infer<typeof brandSchema>;
export const newBrand = (data: unknown): Brand => brandSchema.parse(data);
