import { Title } from "@curaleaf-international/components";
import {
  useDebounce,
  sortByKey,
  Search,
} from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Fuse from "fuse.js";
import { useState, ChangeEvent } from "react";
import { Link as WLink } from "wouter";

import RequiresRoles from "src/components/RequiresRoles";
import { StaffRole } from "src/models";
import { Brand } from "src/models";
import { useBrandsQuery } from "src/queries";

const Brands = () => {
  const { data: brands } = useBrandsQuery();
  const [rawSearch, setRawSearch] = useState("");
  const search = useDebounce(rawSearch);

  const filteredBrands = (brands: Brand[]) => {
    if (search !== "") {
      const fuse = new Fuse(brands, {
        keys: ["name"],
      });
      return fuse.search(search).map((value) => value.item);
    } else {
      return brands.sort(sortByKey((brand) => [brand.name], "asc"));
    }
  };

  return (
    <>
      <Title
        title="Brands"
        actions={
          <RequiresRoles roles={[StaffRole.DEVELOPER, StaffRole.DATA_MASTER]}>
            <Button
              component={WLink}
              to="/brands/new"
              endIcon={<KeyboardArrowDownIcon />}
              variant="contained"
            >
              New Brand
            </Button>
          </RequiresRoles>
        }
      />
      <Card>
        <CardContent>
          <Search
            fullWidth
            label="Search"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setRawSearch(event.target.value)
            }
            value={rawSearch}
            sx={{ marginBottom: 2 }}
          />
        </CardContent>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Brand Name</TableCell>
                <TableCell>Company</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredBrands(brands ?? []).map((brand) => (
                <TableRow key={brand.id}>
                  <TableCell>
                    <Link component={WLink} to={`/brands/${brand.id}/`}>
                      {brand.name}
                    </Link>
                  </TableCell>
                  <TableCell>{brand.company}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default Brands;
