import DownloadIcon from "@mui/icons-material/Download";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Skeleton from "@mui/material/Skeleton";

import { LeafletAudience } from "src/models";

interface IProps {
  audience: LeafletAudience;
  formulaId?: string;
}

const LeafletQRCard = ({ audience, formulaId }: IProps) => (
  <Card>
    <CardHeader title={audience.replaceAll("_", " ")} />
    <CardContent sx={{ textAlign: "center" }}>
      {formulaId !== undefined ? (
        <img
          alt="QR Code to Leaflet"
          width={200}
          src={`/v1/formulas/${formulaId}/qr-code/${audience}/?format=png`}
        />
      ) : (
        <Skeleton height={200} width={200} />
      )}
    </CardContent>
    <CardActions>
      <Button
        component="a"
        disabled={formulaId === undefined}
        download
        href={`/v1/formulas/${formulaId}/qr-code/${audience}/?format=svg`}
        startIcon={<DownloadIcon />}
      >
        SVG
      </Button>
      <Button
        component="a"
        disabled={formulaId === undefined}
        download
        href={`/v1/formulas/${formulaId}/qr-code/${audience}/?format=png`}
        startIcon={<DownloadIcon />}
      >
        PNG
      </Button>
      <Button
        component="a"
        disabled={formulaId === undefined}
        href={`/v1/formulas/${formulaId}/leaflet/${audience}/`}
        target="_blank"
      >
        View
      </Button>
    </CardActions>
  </Card>
);

export default LeafletQRCard;
