import { Unit } from "@curaleaf-international/components";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Decimal } from "decimal.js";

import { newProduct, Product } from "src/models/product";
import { useMutation, useQuery } from "src/query";

export const useFormulasProductsQuery = (formulaId: string) => {
  return useQuery<Product[]>({
    queryKey: ["products", formulaId],
    queryFn: async ({ signal }) => {
      const response = await axios.get(`/v1/products/formula/${formulaId}/`, {
        signal,
      });
      return response.data.products.map((data: unknown) => newProduct(data));
    },
  });
};

export const useProductQuery = (productId: string | undefined) => {
  return useQuery<Product>({
    queryKey: ["product", productId],
    queryFn: async ({ signal }) => {
      const response = await axios.get(`/v1/products/${productId}/`, {
        signal,
      });
      return newProduct(response.data);
    },
    enabled: productId !== undefined,
  });
};

interface IProductData {
  formulaId: string;
  gtin: string | null;
  quantity: Decimal;
  sku: string | null;
  unit: Unit;
}

export const useCreateProductMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IProductData) =>
      await axios.post("/v1/products/", data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["products"] });
    },
  });
};

export const useEditProductMutation = (productId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IProductData) =>
      await axios.put(`/v1/products/${productId}/`, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["products"] });
    },
  });
};
