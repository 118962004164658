import {
  APIError,
  Title,
  ToastContext,
} from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import axios from "axios";
import { useContext, useMemo } from "react";
import { useLocation } from "wouter";

import FormulaForm, {
  ValidatedType,
  NO_SPECIES,
} from "src/components/FormulaForm";
import {
  FlowerGrade,
  Form,
  GmpGacp,
  MicrobeStandard,
  Species,
} from "src/models";
import { useEditFormulaMutation, useFormulaQuery } from "src/queries";

interface IProps {
  formulaId: string;
}

const EditFormula = ({ formulaId }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { data: formula } = useFormulaQuery(formulaId);
  const { mutateAsync: edit } = useEditFormulaMutation(formulaId);
  const defaultValues = useMemo(
    () => ({
      appearance: formula?.appearance ?? "",
      aroma: formula?.aroma ?? "",

      brandId: formula?.brandId ?? 1,
      cbdMin:
        formula?.form === Form.FLOWER
          ? (formula?.cbd?.[0].dividedBy(10) ?? null)
          : (formula?.cbd?.[0] ?? null),
      cbdMax:
        formula?.form === Form.FLOWER
          ? (formula?.cbd?.[1].dividedBy(10) ?? null)
          : (formula?.cbd?.[1] ?? null),
      cbgMin:
        formula?.form === Form.FLOWER
          ? (formula?.cbg?.[0].dividedBy(10) ?? null)
          : (formula?.cbg?.[0] ?? null),
      cbgMax:
        formula?.form === Form.FLOWER
          ? (formula?.cbg?.[1].dividedBy(10) ?? null)
          : (formula?.cbg?.[1] ?? null),
      cbnMin:
        formula?.form === Form.FLOWER
          ? (formula?.cbn?.[0].dividedBy(10) ?? null)
          : (formula?.cbn?.[0] ?? null),
      cbnMax:
        formula?.form === Form.FLOWER
          ? (formula?.cbn?.[1].dividedBy(10) ?? null)
          : (formula?.cbn?.[1] ?? null),
      controlled: formula?.controlled === true ? "true" : "false",
      flowerGrade: formula?.flowerGrade ?? FlowerGrade.STANDARD,
      form: formula?.form ?? Form.FLOWER,
      gmpGacp: formula?.gmpGacp ?? GmpGacp.GMP,
      grower: formula?.grower ?? "",
      ingredients: formula?.ingredients ?? [],
      intendedUse: formula?.intendedUse ?? "",
      irradiationType: formula?.irradiationType ?? "",
      manufacturer: formula?.manufacturer ?? "",
      market: formula?.market ?? "gbr",
      microbeStandard: formula?.microbeStandard ?? "",
      nameInternal: formula?.nameInternal ?? "",
      parentStrain: formula?.parentStrain ?? "",
      registeredName: formula?.registeredName ?? "",
      species: formula?.species ?? NO_SPECIES,
      strain: formula?.strain ?? "",
      terpenes: formula?.terpenes ?? [],
      thcMin:
        formula?.form === Form.FLOWER
          ? (formula?.thc?.[0].dividedBy(10) ?? null)
          : (formula?.thc?.[0] ?? null),
      thcMax:
        formula?.form === Form.FLOWER
          ? (formula?.thc?.[1].dividedBy(10) ?? null)
          : (formula?.thc?.[1] ?? null),
    }),
    [formula],
  );

  const onSubmit = async (data: ValidatedType) => {
    try {
      await edit({
        appearance:
          data.appearance.trim() !== "" ? data.appearance.trim() : null,
        aroma: data.aroma.trim() !== "" ? data.aroma.trim() : null,
        brandId: data.brandId,
        cbd:
          data.cbdMin === null || data.cbdMax === null
            ? null
            : [data.cbdMin, data.cbdMax],
        cbg:
          data.cbgMin === null || data.cbgMax === null
            ? null
            : [data.cbgMin, data.cbgMax],
        cbn:
          data.cbnMin === null || data.cbnMax === null
            ? null
            : [data.cbnMin, data.cbnMax],
        controlled: data.controlled,
        flowerGrade:
          data.form === "FLOWER" ? (data.flowerGrade as FlowerGrade) : null,
        form: data.form as Form,
        gmpGacp: data.gmpGacp as GmpGacp,
        grower: data.grower.trim() !== "" ? data.grower.trim() : null,
        ingredients: data.ingredients,
        intendedUse:
          data.intendedUse.trim() !== "" ? data.intendedUse.trim() : null,
        irradiationType:
          data.irradiationType.trim() !== ""
            ? data.irradiationType.trim()
            : null,
        manufacturer:
          data.manufacturer.trim() !== "" ? data.manufacturer.trim() : null,
        market: data.market,
        microbeStandard:
          data.form === "FLOWER"
            ? (data.microbeStandard as MicrobeStandard)
            : null,
        nameInternal: data.nameInternal,
        parentStrain:
          data.parentStrain.trim() !== "" ? data.parentStrain.trim() : null,
        registeredName:
          data.registeredName.trim() !== "" ? data.registeredName.trim() : null,
        species:
          data.species === "NO_SPECIES" ? null : (data.species as Species),
        strain: data.strain.trim() !== "" ? data.strain.trim() : null,
        terpenes: data.terpenes,
        thc:
          data.thcMin === null || data.thcMax === null
            ? null
            : [data.thcMin, data.thcMax],
      });
      addToast("Formula edited", "success");
      setLocation(`/formulas/${formulaId}/`);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const apiError = error.response?.data as APIError;
        if (apiError.code === "NAME_EXISTS") {
          addToast("This name has already been used.", "error");
        } else {
          addToast("An unexpected error occurred. Please try again.", "error");
        }
      } else {
        addToast("An unexpected error occurred. Please try again.", "error");
      }
    }
  };

  return (
    <>
      <Title
        breadcrumbs={[
          {
            to: "/formulas/",
            label: "Formulas",
          },
          {
            to: `/formulas/${formulaId}/`,
            label: formula?.nameInternal ?? "",
          },
          { label: "Edit formula" },
        ]}
        title="Edit formula"
      />
      <Card>
        <FormulaForm
          defaultValues={defaultValues}
          disabled={formula === undefined}
          label="Edit"
          onSubmit={onSubmit}
        />
      </Card>
    </>
  );
};

export default EditFormula;
