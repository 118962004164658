import { Title, ToastContext } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import { useContext, useMemo } from "react";
import { useLocation } from "wouter";

import BrandForm from "src/components/BrandForm";
import { useCreateBrandMutation } from "src/queries/brands";

const CreateBrand = () => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: create } = useCreateBrandMutation();
  const defaultValues = useMemo(
    () => ({
      company: "",
      name: "",
    }),
    [],
  );

  const onSubmit = async (data: any) => {
    try {
      await create({
        company: data.company,
        name: data.name,
      });
      addToast("Brand added", "success");
      setLocation("/brands/");
    } catch {
      addToast("Error adding brand", "error");
    }
  };

  return (
    <>
      <Title
        breadcrumbs={[
          {
            to: "/brands/",
            label: "Brands",
          },
        ]}
        title="Add brand"
      />
      <Card>
        <BrandForm
          defaultValues={defaultValues}
          label="Add"
          onSubmit={onSubmit}
        />
      </Card>
    </>
  );
};

export default CreateBrand;
