import { zodDecimal } from "@curaleaf-international/components";
import { z } from "zod";

export enum Form {
  CAPSULE = "CAPSULE",
  FLOWER = "FLOWER",
  OIL = "OIL",
  OINTMENT = "OINTMENT",
  PASTILLE = "PASTILLE",
  VAPE_CARTRIDGE = "VAPE_CARTRIDGE",
}

export enum FormulaState {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}

export enum MicrobeStandard {
  EU_5_1_4 = "EU_5_1_4",
  EU_5_1_8b = "EU_5_1_8b",
  EU_5_1_8c = "EU_5_1_8c",
}

export enum PILType {
  ABSENT = "ABSENT",
  GENERATED = "GENERATED",
  UPLOADED = "UPLOADED",
}

export enum Species {
  HYBRID = "HYBRID",
  HYBRID_INDICA = "HYBRID_INDICA",
  HYBRID_SATIVA = "HYBRID_SATIVA",
  INDICA = "INDICA",
  SATIVA = "SATIVA",
}

export enum FlowerGrade {
  STANDARD = "STANDARD",
  SMALL_BUD = "SMALL_BUD",
}

export enum GmpGacp {
  GMP = "GMP",
  GACP = "GACP",
}

const formulaSchema = z.object({
  id: z.string().uuid(),
  appearance: z.string().nullable(),
  approved: z.coerce.date().nullable(),
  approverEmail: z.string().nullable(),
  approverId: z.number().nullable(),
  aroma: z.string().nullable(),
  brandId: z.number(),
  brandName: z.string(),
  cbd: z.tuple([zodDecimal(), zodDecimal()]).nullable(),
  cbg: z.tuple([zodDecimal(), zodDecimal()]).nullable(),
  cbn: z.tuple([zodDecimal(), zodDecimal()]).nullable(),
  company: z.string(),
  controlled: z.boolean(),
  flowerGrade: z.nativeEnum(FlowerGrade).nullable(),
  form: z.nativeEnum(Form),
  gmpGacp: z.nativeEnum(GmpGacp).nullable(),
  grower: z.string().nullable(),
  historyId: z.string().uuid(),
  ingredients: z.array(z.string()),
  intendedUse: z.string().nullable(),
  irradiationType: z.string().nullable(),
  manufacturer: z.string().nullable(),
  market: z.string(),
  microbeStandard: z.nativeEnum(MicrobeStandard).nullable(),
  nameInternal: z.string(),
  parentStrain: z.string().nullable(),
  pilType: z.nativeEnum(PILType),
  qualityReference: z.string().nullable(),
  registeredName: z.string().nullable(),
  species: z.nativeEnum(Species).nullable(),
  staffEmail: z.string().nullable(),
  staffId: z.number().nullable(),
  state: z.nativeEnum(FormulaState),
  strain: z.string().nullable(),
  terpenes: z.array(z.string()),
  thc: z.tuple([zodDecimal(), zodDecimal()]).nullable(),
});

export type Formula = z.infer<typeof formulaSchema>;

export const newFormula = (data: unknown): Formula => formulaSchema.parse(data);
