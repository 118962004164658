import {
  QueryKey,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import axios from "axios";
import { Decimal } from "decimal.js";

import {
  Form,
  Formula,
  FormulaState,
  MicrobeStandard,
  LeafletType,
  Species,
  newFormula,
  FlowerGrade,
  GmpGacp,
} from "src/models";
import { PaginationSettings, PaginatedResult } from "src/pagination";
import { useMutation, useQuery } from "src/query";

export interface FormulasSearchParams {
  approved?: boolean;
  market?: string;
  paginationSettings: Partial<PaginationSettings<Formula>>;
  sortColumn?: "nameInternal" | "market" | "brand";
  searchTerm?: string;
  state?: FormulaState;
}

export const useFormulasQuery = (params: FormulasSearchParams) => {
  const { rawSearchQuery: _ = "", ...paginationParams } =
    params.paginationSettings ?? {};

  return useQuery<PaginatedResult<Formula>>({
    queryKey: [
      "formulas",
      paginationParams,
      params.approved,
      params.market,
      params.state,
    ],
    queryFn: async () => {
      const response = await axios.get("/v1/formulas/", {
        params: {
          ...paginationParams,
          approved: params.approved,
          market: params.market,
          state: params.state,
        },
      });
      return {
        rows: response.data.formulas.map((data: unknown) => newFormula(data)),
        totalRecordCount: response.data.totalRecordCount,
        paginationSettings: response.data.paginationSettings,
      };
    },
  });
};

export const useFormulaQuery = (
  id: string | undefined,
  options?: Omit<
    UseQueryOptions<any, any, Formula, QueryKey>,
    "queryKey" | "queryFn"
  >,
) => {
  const queryClient = useQueryClient();
  return useQuery<Formula>({
    ...options,
    queryKey: ["formulas", id?.toString()],
    queryFn: async ({ signal }) => {
      const response = await axios.get(`/v1/formulas/${id}/`, { signal });
      return newFormula(response.data);
    },
    enabled: id !== undefined,
    initialData: () =>
      id !== undefined
        ? queryClient
            .getQueryData<Formula[]>(["formulas"])
            ?.filter((formula) => formula.id === id)?.[0]
        : undefined,
  });
};

interface IFormulaData {
  appearance: string | null;
  aroma: string | null;
  brandId: number;
  cbd: [Decimal, Decimal] | null;
  cbg: [Decimal, Decimal] | null;
  cbn: [Decimal, Decimal] | null;
  controlled: boolean;
  flowerGrade: FlowerGrade | null;
  form: Form;
  gmpGacp: GmpGacp | null;
  grower: string | null;
  ingredients: string[];
  intendedUse: string | null;
  irradiationType: string | null;
  manufacturer: string | null;
  market: string;
  microbeStandard: MicrobeStandard | null;
  nameInternal: string;
  parentStrain: string | null;
  registeredName: string | null;
  species: Species | null;
  strain: string | null;
  terpenes: string[];
  thc: [Decimal, Decimal] | null;
}

export const useCreateFormulaMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IFormulaData) => {
      await axios.post("/v1/formulas/", data);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["formulas"] });
    },
  });
};

export const useEditFormulaMutation = (formulaId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IFormulaData) =>
      await axios.put(`/v1/formulas/${formulaId}/`, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["formulas"] });
    },
  });
};

interface IFormulaState {
  reason: string;
  state: FormulaState;
}

export const useEditFormulaStateMutation = (formulaId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IFormulaState) =>
      await axios.put(`/v1/formulas/${formulaId}/state/`, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["formulas"] });
    },
  });
};

interface IFormulaLeafletType {
  leafletType: LeafletType;
}

export const useEditFormulaLeafletTypeMutation = (formulaId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IFormulaLeafletType) =>
      await axios.put(`/v1/formulas/${formulaId}/leaflet-type/`, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["formulas"] });
    },
  });
};

export interface IUploadFormulaCSVData {
  files: FileList;
}

export const useUploadFormulaCSVMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IUploadFormulaCSVData) => {
      const formData = new FormData();
      Array.from(data.files).forEach((file) => {
        formData.append("files", file);
      });
      await axios.post("/v1/formulas/upload-csv/", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ["formulas"] });
    },
  });
};
