import { Title, useHash } from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tab from "@mui/material/Tab";
import { useContext, useState } from "react";
import { Link } from "wouter";

import { AuthContext } from "src/AuthContext";
import RequiresRoles from "src/components/RequiresRoles";
import { FormulaState, LeafletType, StaffRole } from "src/models";
import Details from "src/pages/Formula/Details";
import History from "src/pages/Formula/History";
import Leaflets from "src/pages/Formula/Leaflets";
import Products from "src/pages/Formula/Products";
import StateHistory from "src/pages/Formula/StateHistory";
import { useFormulaQuery } from "src/queries";

type TabState =
  | "details"
  | "history"
  | "leaflets"
  | "products"
  | "stateHistory";

interface IProps {
  formulaId: string;
}

const Formula = ({ formulaId }: IProps) => {
  const { member } = useContext(AuthContext);
  const { data: formula } = useFormulaQuery(formulaId);
  const [tab, setTab] = useHash<TabState>("details");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
        <MenuItem component={Link} to={`/formulas/${formulaId}/edit/`}>
          Edit
        </MenuItem>
        <MenuItem
          component={Link}
          to={`/formulas/${formulaId}/edit-leaflet-type/`}
        >
          Edit Leaflet Type
        </MenuItem>
        <MenuItem component={Link} to={`/formulas/${formulaId}/new-product/`}>
          New Product
        </MenuItem>
        <MenuItem
          component={Link}
          disabled={
            formula === undefined || formula?.state === FormulaState.ARCHIVED
          }
          to={`/formulas/${formulaId}/archive/`}
        >
          Archive formula
        </MenuItem>
        <MenuItem
          component={Link}
          disabled={
            formula === undefined || formula?.state !== FormulaState.ARCHIVED
          }
          to={`/formulas/${formulaId}/unarchive/`}
        >
          Unarchive formula
        </MenuItem>
        <MenuItem
          component={Link}
          disabled={
            formula === undefined ||
            formula?.leafletType !== LeafletType.UPLOADED
          }
          to={`/formulas/${formulaId}/upload-leaflet/`}
        >
          Upload Leaflet
        </MenuItem>
      </Menu>
      <Title
        actions={
          <RequiresRoles
            roles={[
              StaffRole.DATA_MASTER,
              StaffRole.DEVELOPER,
              StaffRole.LEAFLET_MASTER,
            ]}
          >
            <Button
              endIcon={<KeyboardArrowDownIcon />}
              onClick={(event) => setAnchorEl(event.currentTarget)}
              variant="contained"
            >
              Actions
            </Button>
          </RequiresRoles>
        }
        breadcrumbs={[
          {
            to: "/formulas/",
            label: "Formulas",
          },
        ]}
        title={formula?.nameInternal ?? ""}
      />
      <TabContext value={tab}>
        <TabList
          aria-label="Formula menu"
          onChange={(_, value: string) => setTab(value as TabState)}
          sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 2 }}
        >
          <Tab label="Details" value="details" />
          <Tab label="Products" value="products" />
          <Tab label="Leaflets" value="leaflets" />
          {member?.roles.some((role) =>
            [
              StaffRole.DEVELOPER,
              StaffRole.DATA_MASTER,
              StaffRole.LEAFLET_MASTER,
              StaffRole.QUALITY,
            ].includes(role),
          ) ? (
            <Tab label="History" value="history" />
          ) : null}
          {member?.roles.some((role) =>
            [
              StaffRole.DEVELOPER,
              StaffRole.DATA_MASTER,
              StaffRole.LEAFLET_MASTER,
              StaffRole.QUALITY,
            ].includes(role),
          ) ? (
            <Tab label="State History" value="stateHistory" />
          ) : null}
        </TabList>
        <TabPanel value="details">
          <Details formula={formula} />
        </TabPanel>
        <TabPanel value="products">
          <Products formulaId={formulaId} />
        </TabPanel>
        <TabPanel value="leaflets">
          <Leaflets formula={formula} />
        </TabPanel>
        <TabPanel value="history">
          <History formulaId={formulaId} />
        </TabPanel>
        <TabPanel value="stateHistory">
          <StateHistory formulaId={formulaId} />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default Formula;
