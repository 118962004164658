import {
  sortByKey,
  useHash,
  SkeletonRow,
  Title,
} from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useContext } from "react";
import { useState } from "react";
import { Link as WLink } from "wouter";

import { AuthContext } from "src/AuthContext";
import PaginatedSearch from "src/components/PaginatedSearch";
import TablePagination from "src/components/TablePagination";
import TableSortLabel from "src/components/TableSortLabel";
import { Formula, FormulaState } from "src/models";
import { usePaginationSettings } from "src/queries";
import { useFormulasQuery } from "src/queries";

type States = "active" | "archived";

const Formulas = () => {
  const { member } = useContext(AuthContext);
  const [tab, setTab] = useHash<States>("active");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [
    paginationSettings,
    { setPageNumber, setPageSize, toggleSort, setSearchQuery },
  ] = usePaginationSettings<Formula>({
    pageNumber: 0,
    pageSize: 100,
    sortColumn: "nameInternal",
    sortDirection: "desc",
  });

  const { data: formulas } = useFormulasQuery({
    paginationSettings,
    state: tab === "active" ? FormulaState.ACTIVE : FormulaState.ARCHIVED,
  });
  const { totalRecordCount } = formulas || {};

  let rows: React.ReactNode = <SkeletonRow cols={3} />;

  if (formulas !== undefined && formulas.rows.length > 0) {
    rows = formulas.rows
      .sort(
        sortByKey(
          (formula: any) =>
            formula[paginationSettings.sortColumn ?? "nameInternal"],
          paginationSettings.sortDirection === "desc" ? "desc" : "asc",
        ),
      )
      .map((formula: any) => (
        <TableRow key={formula.id}>
          <TableCell>
            <Link component={WLink} to={`/formulas/${formula.id}/`}>
              {formula.nameInternal}
            </Link>
          </TableCell>
          <TableCell>{formula.market.toUpperCase()}</TableCell>
          <TableCell>{formula.brand}</TableCell>
        </TableRow>
      ));
  } else if (formulas !== undefined) {
    rows = (
      <TableRow>
        <TableCell colSpan={3}>No formulas found</TableCell>
      </TableRow>
    );
  }

  return (
    <>
      <Title
        actions={
          (member?.roles.length ?? 0) > 0 ? (
            <>
              <Button
                component={WLink}
                endIcon={<KeyboardArrowDownIcon />}
                onClick={(event) => setAnchorEl(event.currentTarget)}
                variant="contained"
              >
                Actions
              </Button>
            </>
          ) : null
        }
        title="Formulas"
      />
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
        <MenuItem component={WLink} to="/formulas/new/">
          New
        </MenuItem>
        <MenuItem
          component="a"
          href="/v1/formulas/csv/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Download CSV
        </MenuItem>
        <MenuItem component={WLink} to="/formulas/upload-csv/">
          Upload CSV
        </MenuItem>
      </Menu>
      <Card>
        <TabContext value={tab}>
          <TabList
            onChange={(_, value: string) => setTab(value as States)}
            sx={{ borderBottom: 1, borderColor: "divider", paddingLeft: 3 }}
          >
            <Tab label="Active" value="active" />
            <Tab label="Archived" value="archived" />
          </TabList>
          <CardContent>
            <PaginatedSearch
              setSearchQuery={setSearchQuery}
              paginationSettings={paginationSettings}
            />
          </CardContent>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel<Formula>
                      columnName="nameInternal"
                      paginationSettings={paginationSettings}
                      toggleSort={toggleSort}
                    >
                      Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel<Formula>
                      columnName="market"
                      paginationSettings={paginationSettings}
                      toggleSort={toggleSort}
                    >
                      Market
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel<Formula>
                      columnName={"brandId"}
                      paginationSettings={paginationSettings}
                      toggleSort={toggleSort}
                    >
                      Brand
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{rows}</TableBody>
            </Table>
            <TablePagination<Formula>
              recordCount={totalRecordCount}
              paginationSettings={paginationSettings}
              setPageNumber={setPageNumber}
              setPageSize={setPageSize}
              rowsPerPageOptions={[100, 500, 750, 1000]}
            />
          </TableContainer>
        </TabContext>
      </Card>
    </>
  );
};

export default Formulas;
