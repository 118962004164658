import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { PIL, newPIL } from "src/models";
import { useMutation, useQuery } from "src/query";

export const useFormulaPILsQuery = (formulaId: string | undefined) =>
  useQuery<PIL[]>({
    queryKey: ["pils", formulaId],
    queryFn: async ({ signal }) => {
      const response = await axios.get(`/v1/pils/formula/${formulaId}/`, {
        signal,
      });
      return response.data.pils.map((pil: unknown) => newPIL(pil));
    },
    enabled: formulaId !== undefined,
  });

export const useCreatePILMutation = (formulaId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (fileList: File[]) => {
      const formData = new FormData();
      fileList.forEach((file) => formData.append("file", file));
      await axios.post(`/v1/pils/formula/${formulaId}/`, formData);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["pils", formulaId],
      });
    },
  });
};
