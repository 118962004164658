import { SelectField } from "@curaleaf-international/components";

const MARKETS = {
  aus: "Australia",
  gbr: "United Kingdom",
  nzl: "New Zealand",
};

interface IProps {
  fullWidth?: boolean;
  helperText?: string;
  label: string;
  multiple?: boolean;
  name: string;
  required?: boolean;
}

const MarketField = ({
  fullWidth,
  helperText,
  label,
  multiple,
  name,
  required,
}: IProps) => (
  <SelectField
    autoComplete="off"
    fullWidth={fullWidth}
    helperText={helperText}
    label={label}
    multiple={multiple}
    name={name}
    options={Object.entries(MARKETS).map(([value, label]) => ({
      label,
      value,
    }))}
    required={required}
  />
);

export default MarketField;
