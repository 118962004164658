import { Title, ToastContext, Unit } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import { useContext, useMemo } from "react";
import { useLocation } from "wouter";

import ProductForm from "src/components/ProductForm";
import { ValidatedType } from "src/components/ProductForm";
import {
  useEditProductMutation,
  useFormulaQuery,
  useProductQuery,
} from "src/queries";

interface IProps {
  productId: string;
}

const EditProduct = ({ productId }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { data: product } = useProductQuery(productId);
  const { data: formula } = useFormulaQuery(product?.formulaId);
  const { mutateAsync: edit } = useEditProductMutation(productId);

  const defaultValues = useMemo(
    () => ({
      gtin: product?.gtin ?? "",
      quantity: product?.quantity ?? "",
      sku: product?.sku ?? "",
      unit: product?.unit ?? Unit.G,
    }),
    [product],
  );

  const onSubmit = async (data: ValidatedType) => {
    try {
      await edit({
        formulaId: product!.formulaId,
        gtin: data.gtin.trim() === "" ? null : data.gtin.trim(),
        quantity: data.quantity,
        sku: data.sku.trim() === "" ? null : data.sku.trim(),
        unit: data.unit,
      });
      addToast("Product edited", "success");
      setLocation(`/formulas/${product?.formulaId}/`);
    } catch {
      addToast("Error editing product", "error");
    }
  };

  return (
    <>
      <Title
        breadcrumbs={[
          {
            to: "/products/",
            label: "Products",
          },
          {
            to: `/formulas/${product?.formulaId}/`,
            label: formula?.nameInternal ?? "",
          },
        ]}
        title="Edit product"
      />
      <Card>
        <ProductForm
          defaultValues={defaultValues}
          disabled={formula === undefined || product === undefined}
          label="Edit"
          onSubmit={onSubmit}
        />
      </Card>
    </>
  );
};

export default EditProduct;
