import { ScrollToTop, NotFound } from "@curaleaf-international/components";
import { Route, Switch } from "wouter";

import PageWrapper from "src/components/PageWrapper";
import PrivateRoute from "src/components/PrivateRoute";
import { FormulaState } from "src/models";
import Activities from "src/pages/Activities";
import ApproveFormulaHistory from "src/pages/ApproveFormulaHistory";
import Brand from "src/pages/Brand";
import Brands from "src/pages/Brands";
import CreateBrand from "src/pages/CreateBrand";
import CreateFormula from "src/pages/CreateFormula";
import CreateProduct from "src/pages/CreateProduct";
import Dashboard from "src/pages/Dashboard";
import DisableStaff from "src/pages/DisableStaff";
import EditBrand from "src/pages/EditBrand";
import EditFormula from "src/pages/EditFormula";
import EditFormulaState from "src/pages/EditFormulaState";
import EditProduct from "src/pages/EditProduct";
import EditStaffMember from "src/pages/EditStaffMember";
import Formula from "src/pages/Formula";
import Formulas from "src/pages/Formulas";
import HelpEndpointRoles from "src/pages/HelpEndpointRoles";
import Login from "src/pages/Login";
import Quality from "src/pages/Quality";
import Staff from "src/pages/Staff";
import StaffMember from "src/pages/StaffMember";
import UploadFormulasCSV from "src/pages/UploadFormulasCSV";
import UploadPIL from "src/pages/UploadPIL";

const ThrowError = () => {
  throw new Error("Test Error");
};

const UUID_REGEX =
  "[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}";

const Router = () => (
  <PageWrapper>
    <ScrollToTop />
    <Switch>
      <PrivateRoute path="/">
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute path="/activities/">
        <Activities />
      </PrivateRoute>
      <PrivateRoute
        path={RegExp(`^/formula-histories/(?<id>${UUID_REGEX})/approval/$`)}
      >
        {(params: any) => (
          <ApproveFormulaHistory formulaHistoryId={params.id} />
        )}
      </PrivateRoute>
      <PrivateRoute path="/formulas/">
        <Formulas />
      </PrivateRoute>
      <PrivateRoute path={RegExp(`^/formulas/(?<id>${UUID_REGEX})/$`)}>
        {(params: any) => <Formula formulaId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path={RegExp(`^/formulas/(?<id>${UUID_REGEX})/edit/$`)}>
        {(params: any) => <EditFormula formulaId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path={RegExp(`^/formulas/(?<id>${UUID_REGEX})/archive/$`)}>
        {(params: any) => (
          <EditFormulaState
            formulaId={params.id}
            state={FormulaState.ARCHIVED}
          />
        )}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp(`^/formulas/(?<id>${UUID_REGEX})/new-product/$`)}
      >
        {(params: any) => <CreateProduct formulaId={params.id} />}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp(`^/formulas/(?<id>${UUID_REGEX})/unarchive/$`)}
      >
        {(params: any) => (
          <EditFormulaState formulaId={params.id} state={FormulaState.ACTIVE} />
        )}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp(`^/formulas/(?<id>${UUID_REGEX})/upload-pil/$`)}
      >
        {(params: any) => <UploadPIL formulaId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path="/formulas/upload-csv/">
        <UploadFormulasCSV />
      </PrivateRoute>
      <PrivateRoute path="/formulas/new/">
        <CreateFormula />
      </PrivateRoute>
      <PrivateRoute path={RegExp(`^/products/(?<id>${UUID_REGEX})/edit/$`)}>
        {(params: any) => <EditProduct productId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path="/quality/">
        <Quality />
      </PrivateRoute>
      <PrivateRoute path="/staff/">
        <Staff />
      </PrivateRoute>
      <PrivateRoute path={"/staff/:id/"}>
        {(params: any) => <StaffMember staffId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path="/staff/:id/edit/roles/">
        {(params: any) => <EditStaffMember staffId={parseInt(params.id)} />}
      </PrivateRoute>
      <PrivateRoute path={"/staff/:id/disabled/"}>
        {(params: any) => <DisableStaff staffId={parseInt(params.id)} />}
      </PrivateRoute>
      <PrivateRoute path="/help/endpoint-roles/">
        <HelpEndpointRoles />
      </PrivateRoute>
      <Route path="/login/">
        <Login />
      </Route>
      <Route path="/control/fe-error/">
        <ThrowError />
      </Route>
      <PrivateRoute path="/brands/">
        <Brands />
      </PrivateRoute>
      <PrivateRoute path="/brands/new/">
        <CreateBrand />
      </PrivateRoute>
      <PrivateRoute path={RegExp(`^/brands/(?<id>(\\d+))/$`)}>
        {(params: any) => <Brand brandId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path={RegExp(`^/brands/(?<id>(\\d+))/edit/$`)}>
        {(params: any) => <EditBrand brandId={params.id} />}
      </PrivateRoute>
      <Route path="/(.*)">
        <NotFound />
      </Route>
    </Switch>
  </PageWrapper>
);

export default Router;
